.banner {
    position: relative;
    
    iframe {
        display: table;
    }

    video {
        width: 100%;
        height: auto;
    }

    img {
        width: 100%;
    }
}

.banner__content {
    position: absolute;
    left: 0;
    bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: 0 30px;
    color: #fff;

    .btn {
        margin-top: 40px;
        padding: 7px 20px;
        font-size: 18px;
    }
}

.banner__title {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 25px;
}

.banner__subtitle {
    font-size: 28px;
    font-weight: 300;
}
