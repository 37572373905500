.overview__block {
    display: block;
    margin-bottom: 40px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);

    &:hover {
        .overview__block__img__inner {
            img {
                transform: scale(1.05);
            }
        }

        .overview__block__readmore {
            svg {
                margin-left: 12px;
            }
        }
    }
}

.overview__block__content {
    display: block;
    background-color: #fff;
    padding: 39px 45px 39px 45px;
}

.overview__block__title {
    display: block;
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 0;
    font-size: 18px;
    color: $primarycolor;
}

.overview__block__date {
    display: block;
    color: $body-font-color;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;

    svg {
        width: 16px;
        height: 18px;
        margin-right: 8px;
        color: $secondarycolor;
        fill: $secondarycolor;
    }
}

.overview__block__summary {
    display: block;
    margin-top: 13px;
    font-size: 16px;
    line-height: 1.5;
    color: $body-font-color;
}

.overview__block__img {
    position: relative;
    display: block;
    background-color: #fff;
}

.overview__block__img__inner {
    display: block;
    width: 100%;
    overflow: hidden;

    img {
        max-width: 100%;
        transition: all 0.3s ease;
    }
}

.overview__block__readmore {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    font-weight: 600;
    color: $primarycolor;
    font-size: 18px;
    transition: all 0.3s;

    svg {
        width: 29px;
        height: 21px;
        margin-top: 3px;
        transition: all 0.3s ease;
        color: $secondarycolor;
        fill: $secondarycolor;
    }
}
