@media screen and (max-width: 767px) {
    body {
        font-size: 15px;
    }

    .spacing {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    h1 {
        font-size: 26px;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 23px;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 21px;
    }

    h4 {
        font-size: 19px;
    }

    h5 {
        font-size: 18px;
    }

    h6 {
        font-size: 17px;
    }

    .overview__block__content {
        padding: 19px 25px 19px 25px;
    }

    .overview__block__readmore {
        width: 22px;
        height: 17px;
        margin-top: 0;
    }

    .header__right {
        position: absolute;
        top: 0;
        right: 0;
    }

    .header {
        &.header--fix {
            .brand {
                img {
                    max-width: 110px;
                }
            }
        }
    }

    .footer__bottom__btn__spacing {
        margin-right: 20px;
    }

    .footer__bottom__btn {
        margin-bottom: 0;
        font-size: 18px;

        &__icon {
            svg {
                height: 20px;
            }
        }

        &__icon__flipped {
            svg {
                height: 20px;
            }
        }
    }

    .partner__container {
        margin: 100px 0 -75px 0;
        padding: 0;
    }

    .partner__slider__inner {
        margin-bottom: 0;

        &.founding {
            margin-bottom: 100px;
        }

        &::before {
            padding: 10px 20px;
            font-size: 16px;
        }
    }

    .quote__outer {
        padding-left: 0;
        padding-right: 0;
    }

    .quote__container {
        padding: 20px 80px 40px 80px;
        font-size: 18px;

        .quote__marks__before {
            top: 0;
            left: -40px;
        }

        .quote__marks__after {
            right: -40px;
            bottom: 0;
        }
    }

    .quote__marks__before svg,
    .quote__marks__after svg {
        width: 30px;
        height: 20px;
    }

    .front__beeldmerk,
    .subpage__beeldmerk {
        overflow: hidden;

        .svg__beeldmerk {
            width: 160%;
        }
    }

    .front__beeldmerk__content {
        position: relative;
        top: -1px;
        right: 0;
        width: 100%;
        background-color: $primarycolor;

        svg {
            margin-top: 20px;
            width: 21px;
            height: 34px;
        }
    }

    .front__beeldmerk__title {
        font-size: 24px;
        line-height: 28px;
    }

    .front__intro {
        padding: 50px 0 340px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 24px;
        }
    }

    .howitworks__intro {
        padding-bottom: 200px;
    }

    .video__intro {
        padding: 0 0 275px;
    }

    .howitworks {
        .front__beeldmerk {
            svg {
                width: 140%;
                transform: translateX(-15%);
                position: relative;
                bottom: -1px;
            }

            .howitworks__beeldmerk__content {
                svg {
                    width: 100%;
                    transform: unset;
                    margin-top: 10px;
                }
            }
        }
    }

    .howitworks__beeldmerk__content {
        position: relative;
        background-color: $primarycolor;
        top: 0;

        .front__beeldmerk__title {
            position: relative;
            top: -30px;
        }
    }

    .howitworks__container {
        padding: 0 0 30px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 24px;
        }
    }

    .howitworks__item__image {
        width: 125px;
        margin: 0 10px 10px;
        border: 7px solid #fff;
    }

    .team__title__container {
        .team__icon {
            svg {
                width: 70px;
                height: 42px;
            }
        }
    }

    .process__container {
        padding: 20px 0;

        &::before {
            content: '';
            background: linear-gradient(180deg, rgba(255, 221, 0, 1) 0%, rgba(255, 206, 0, 1) 12%, rgba(238, 119, 0, 1) 28%, rgba(230, 29, 32, 1) 40%, rgba(228, 5, 40, 1) 44%, rgba(229, 1, 101, 1) 63%, rgba(199, 84, 154, 1) 81%, rgba(0, 159, 227, 1) 100%);
            top: 10%;
            left: 28px;
            width: 6px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 21px;
        }
    }

    .process__item {
        align-items: flex-start;
        margin-left: 0 !important;

        &:not(:last-child) {
            margin-bottom: 40px;
        }

        &:last-child {
            background-color: $primarycolor;
        }
    }

    .process__item__image {
        padding: 20px;
        height: 100px;
        width: 100px;
        margin-left: -20px;
    }

    .process_item__content {
        padding-top: 30px;
        padding-left: 0;
    }

    .application__anchor {
        margin: 40px 0 0;
        font-size: 18px;

        svg {
            margin-top: 10px;
            height: 24px;
            width: 24px;
        }
    }

    .subpage__intro {
        padding-top: 50px;

        h1 {
            margin-bottom: 30px;
        }
    }

    .main__container {
        padding: 20px 25px;
        border-radius: 15px;
    }

    .siderbar {
        margin-top: 20px;
    }

    .sidebar__image {
        margin-bottom: 25px;
        border-radius: 15px;
    }

    .sidebar__news__header {
        font-size: 22px;
    }

    .sidebar__specs {
        padding: 20px 25px;
        border-radius: 15px;
    }

    .sidebar__spec__header {
        margin-bottom: 10px;
        font-size: 22px;
    }

    .sidebar__specs__item {
        font-size: 15px;
    }

    .overview__button {
        margin-top: 20px;
    }

    .howitworks__spacer {
        margin-top: 30px;

        svg {
            width: 27px;
            height: 39px;
        }
    }

    .howitworks__content__block__title {
        font-size: 21px;
    }

    .howitworks__item__group {
        margin-top: -10px;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .howitworks__item__color {
        height: 40px;
        width: 40px;
        margin: 0 10px 10px;
        border: 4px solid #fff;

        &:nth-child(even) {
            margin: 0 10px 10px;
        }
    }

    .howitworks__footer {
        padding: 25px 30px;
        margin-top: 40px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 23px;
        }
    }

    .contact__right {
        margin-top: 100px;
    }

    .partners__item__content {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 20px;
        }
    }

    .accordion {
        .card-header {
            svg {
                height: 18px;
                width: 18px;
            }
        }
    }
}
