@import "common/variables";

/** Import everything from autoload */
@import "./autoload/_bootstrap.scss";

/**
* Import npm dependencies
*
* Prefix your imports with `~` to grab from node_modules/
* @see https://github.com/webpack-contrib/sass-loader#imports
*/
// @import "~some-node-module";

// Import Slick from node_modules
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

// Import WOWJS from node_modules
@import "~wowjs/css/libs/animate.css";

// Import fontawesome via package manager
// @import "~@fortawesome/fontawesome-pro/css/all.css";

// Import Fancybox from node_modules
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";

/** Import theme styles */
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/menu";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/contact";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

/** Import template styles */
@import "template/banner";
@import "template/search";
@import "template/page-content";

/** Import template onderdelen styles */
// @import "template/diensten/version1";
// @import "template/team/version1";
// @import "template/tijdlijn/version1";
@import "template/nieuws/verison1";

/** Import responsive styles */
@import "layouts/responsive-1199px+";
@import "layouts/responsive-1199px";
@import "layouts/responsive-991px";
@import "layouts/responsive-767px";
@import "layouts/responsive-575px";
@import "layouts/responsive-575px-";
