.marker__inner {
    display: block;
    padding: 1px 10px 3px 10px;
}

.marker__title {
    display: block;
    margin-top: 5px;
    font-size: 18px;
    font-weight: 700;
    color: $primarycolor;
    text-align: center;
}

.marker__item {
    display: block;
    margin-bottom: 5px;
    width: 100%;
    font-size: 15px;
    text-align: center;

    &.btn-primary {
        margin-top: 10px;
    }
}

.banner {
    display: none;
}

.contact__title {
    position: relative;
    display: block;
    font-size: 38px;
    font-weight: 600;
    font-style: italic;
    color: $primarycolor;
    margin: 110px 0 80px 0;
}

.contact__left__text {
    position: relative;
    display: block;
    color: $primarycolor;
    font-weight: 300;
    margin-bottom: 40px;
}

.contact__left__required {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 30px;
    margin-top: -30px;
    z-index: -1;

    span {
        color: $primarycolor;
        font-weight: 300;
    }
}

.gform_footer {
    margin-top: 40px;
    width: 200px;
}

.gform_button {
    @extend .btn-primary, .btn-next ;
}

.main__container__contact {
    margin-bottom: 160px !important;
}

.contact__page {
    background-color: #2c2f84;
    padding-bottom: 160px;
}

.contact__right__container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    flex-direction: row;
    padding-left: 75px;
}

.contact__right__map {
    display: block;
    position: relative;
    width: 100%;
    height: 267px;
    border-radius: 30px;
    border: 1px solid #d8d8d8;
    background-color: #f5f5f5;
    overflow: hidden;
}

.contact__right__map img {
    object-fit: cover;
    height: 100%;
}

.contact__right__details {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f5f5f5;
    border-radius: 30px;
    width: 100%;
    height: auto;
    padding: 50px;
}

.contact__container__block {
    position: relative;
    display: block;
    color: $primarycolor;
    margin-top: 25px;

    &:nth-of-type(1) {
        margin-top: 0;
    }
}

.contact__container__inner {
    width: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    transition: all 0.4s ease;
    height: 24px;
    margin-top: 2px;

    svg {
        width: 18px;
        height: 18px;
        align-self: center;
        margin-right: 18px;
        transition: all 0.4s ease;
    }
}

.contact__container__social:hover {
    color: $secondarycolor;

    svg {
        color: $secondarycolor;
    }
}

.flip__icon {
    svg {
        transform: rotate(-270deg);
    }
}

.contact__container__row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    font-weight: 300;

    &__titel {
        font-weight: 600;
    }
}

.contact__right__titel {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    margin: 50px 0 10px 0;

    h2 {
        color: $primarycolor;
        font-size: 24px;
        font-weight: 600;
        font-style: italic;
    }
}

.contact__container__location {
    position: relative;
    display: block;
    width: 100px;
    margin-right: 30px;
}

.hidden__field {
    display: none;
}
