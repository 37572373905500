.btn-primary,
.gform_button {
    padding: 15px 30px;
    border-radius: 50px;
    border: 3px solid $primarycolor;
    color: $primarycolor;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background: transparent;
    position: relative;

    &:hover,
    &:active,
    &:focus {
        color: $secondarycolor;
        border-color: $secondarycolor;
        background-color: transparent;
    }
}

.btn-next,
.gform_button {
    padding-right: 62px;
    transition: all 0.4s ease;

    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 9px);
        right: 20px;
        background-image: url("../../svg/arrow-right.svg");
        background-size: cover;
        width: 25px;
        height: 18px;
        transition: all 0.4s ease;
    }

    &:hover {
        &::after {
            right: 17px;
        }
    }
}

.btn-previous {
    padding-left: 62px;
    transition: all 0.6s ease;

    &::before {
        content: "";
        position: absolute;
        top: calc(50% - 9px);
        left: 20px;
        background-image: url("../../svg/arrow-right.svg");
        background-size: cover;
        transform: scale(-1);
        width: 25px;
        height: 18px;
    }

    &:hover {
        color: $secondarycolor;
        border-radius: 10px;
        border-color: $secondarycolor;
    }
}

.btn-secondary {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 62px;
    border-radius: 50px;
    border: 3px solid $primarycolor;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background: $primarycolor;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 9px);
        right: 20px;
        background-image: url("../../svg/arrow-right.svg");
        background-size: cover;
        width: 25px;
        height: 18px;
        transition: all 0.4s ease;
    }

    &:hover {
        color: $secondarycolor;
        border-color: $secondarycolor;

        &::after {
            right: 18px;
            background-image: url("../../svg/arrow-right-hover.svg");
        }
    }

    &:hover,
    &:active,
    &:focus {
        background-color: $secondarycolor;
        color: #fff;
    }
}

.btn-orange {
    background-color: #dc410d;
    border-color: #dc410d;
    padding: 15px 30px;

    &:hover,
    &:active,
    &:focus {
        background-color: darken(#dc410d, 3) !important;
        border-color: darken(#dc410d, 3) !important;
    }

    &::after {
        display: none;
    }
}
