@media screen and (max-width: 380px) {
    .home__team__container {
        padding: 50px 0 0 0;
    }

    .quote__container {
        padding: 20px 20px 50px 20px;
    }

    .app__inner__title {
        font-size: 16px;
        height: auto !important;
    }

    .app__inner__summary {
        font-size: 14px;
    }

    .app__parent {
        padding-bottom: 50px;
    }
}
