// Footer common

.footer {
    padding: 200px 0 0;
    position: relative;
    display: flex;
    background: linear-gradient(to right, #1fa6e3, #0084c9, #0064ad, #00448f, #00256f);

    &::before {
        content: "";
        background-image: url("../../svg/pattern.svg");
        background-size: 25px 25px;
        background-repeat: repeat;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        opacity: 0.1;
    }
}

// Footer spacing

.footer__vertical__spacing {
    margin-top: 45px;
}

.footer__bottom__btn__spacing {
    margin-right: 25px;
    display: flex;
}

.footer__newsletter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0 0;
}

#popup__newsletter {
    .gform_fields > li {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .gform_body {
        margin-top: 25px;
    }
}

// Footer styling

.footer__top {
    text-align: center;
    display: block;
    position: relative;
    width: 100%;
    color: #fff;

    &__title {
        font-size: 43px;
        line-height: 50px;
        font-style: italic;
        font-weight: 600;
    }

    &__sub {
        font-size: 26px;
        font-weight: 500;
        line-height: 26px;
    }
}

.footer__bottom {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    flex-flow: row wrap;

    &__btn {
        color: #fff;
        width: auto;
        height: 26px;
        line-height: 26px;
        font-size: 22px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.4s ease;
        position: relative;

        &:hover {
            color: #fff;
        }

        &__icon__flipped {
            svg {
                transform: rotateY(-180deg);
                height: 22px;
            }
        }

        &__icon {
            svg {
                height: 22px;
                width: 28px;
            }
        }
    }
}

.footer__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 180px 0 20px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
}

.footer__menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    li {
        &:not(:last-child) {
            &::after {
                content: "|";
                padding: 0 15px;
            }
        }
    }

    a {
        color: rgba(255, 255, 255, 0.7);
        transition: all 0.3s ease;

        &:hover {
            color: rgba(255, 255, 255, 1);
        }
    }
}
