.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: all 300ms ease-in-out, left 0ms ease-in-out;
    padding-top: 65px;

    &.header--fix {
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        transition: all 300ms ease-in-out, left 0ms ease-in-out;
        padding: 10px 0;

        .brand {
            transition: all 300ms ease-in-out;
            margin-top: 5px;

            img {
                max-width: 180px;
                width: 100%;
                transition: all 300ms ease-in-out;
            }
        }

        .menu__icon {
            span {
                background-color: $primarycolor;
            }
        }

        .nav-primary {
            top: 0;
            transition: all 300ms ease-in-out;

            svg {
                fill: $primarycolor;
            }

            .nav {
                > li {
                    a {
                        padding-top: 30px;
                        padding-bottom: 30px;
                    }
                }

                .sub-menu {
                    li {
                        a {
                            padding-top: 10px;
                            padding-bottom: 10px;
                        }
                    }
                }
            }
        }

        .header__left,
        .header__right {
            &::before {
                background-color: transparent;
            }
        }
    }
}

.header .nav li {
    @extend .nav-item;
}

.header .nav a {
    @extend .nav-link;
}

.brand {
    position: relative;
    display: block;
    max-width: 315px;

    img {
        width: 100%;
        max-width: 100%;
        transition: all 300ms ease-in-out;
    }
}

.nav__home {
    margin-right: 15px;

    svg {
        position: relative;
        top: -2px;
        height: 22px;
        width: 22px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}
