.ie11 {
    .front-page-data {
        .svg__beeldmerk {
            display: none;
        }

        .front__content {
            background-image: url('../../images/iebg.png');
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }

    .process__container::before {
        background-position-x: -320px;
    }

    .howitworks__beeldmerk__content {
        bottom: 100px;
    }

    .home__team__left,
    .home__team__right {
        flex: 0 0 45%;
    }

    .howitworks {
        .front__beeldmerk {
            > svg {
                position: relative;
                width: 100%;
                height: 238px;
                top: -235px;
            }
        }
    }

    .footer__bottom,
    .footer__bottom__btn {
        justify-content: center;
    }

    .footer__bottom__btn__spacing {
        margin-right: 10px;
        margin-left: 40px;
    }

    .subpage__beeldmerk {
        .svg__beeldmerk {
            width: 100%;
            height: 881.13px;
        }
    }
}

/* Homepage Blocks */

.spacing {
    position: relative;
    padding-top: 0;
    padding-bottom: 150px;
}

.spacing-top {
    padding-top: 77px;
}

/* Pagination numbered */

.pagination__numbers {
    border-top: 1px solid #eee;
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;

    .page-numbers {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        background: $primarycolor;
        display: flex;
        float: left;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 15px;
        margin: 0 3px;
        text-decoration: none;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            background-color: darken($primarycolor, 5);
            text-decoration: none;
        }

        &.current {
            background-color: darken($primarycolor, 5);
        }
    }
}

.row__list {
    margin-top: 40px;
}

.app__container {
    position: relative;
    display: block;
}

.front__beeldmerk {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transform: translateY(calc(-100% + 2px));

    .svg__beeldmerk {
        width: 100%;
    }
}

.front__beeldmerk__content {
    position: absolute;
    z-index: 1;
    bottom: 25%;
    right: 10%;
    text-align: center;

    svg {
        margin-top: 40px;
        width: 33px;
        height: 47px;
        color: #fff;
        fill: #fff;
    }
}

.front__beeldmerk__title {
    font-size: 43px;
    font-weight: 300;
    font-style: italic;
    color: #fff;
    line-height: 52px;

    span {
        display: block;
        font-weight: 600;
    }
}

.page__content {
    position: relative;
    background-color: $primarycolor;
    padding-bottom: 40px;
}

.front__intro {
    position: relative;
    z-index: 1;
    display: block;
    padding: 170px 0 400px;
    max-width: 650px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 38px;
        font-weight: 600;
        font-style: italic;
        color: $primarycolor;
    }

    .btn {
        margin-top: 10px;
    }
}

.howitworks__intro {
    @extend .front__intro;

    padding-top: 100px;
    padding-bottom: 240px;
}

.video__intro {
    position: relative;
    z-index: 1;
    padding: 120px 0 300px;
}

.video__intro--inner {
    background-color: #eee;
    border-radius: 30px;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.main__container {
    position: relative;
    z-index: 2;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
    border-radius: 30px;
    background-color: #fff;
    padding: 80px 90px;
    margin-bottom: 80px;
}

.subpage__beeldmerk {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 60px;
    background-color: #fff;
}

.subpage__intro {
    padding-top: 100px;

    h1 {
        margin-bottom: 60px;
    }
}

.overview__button {
    margin-top: 40px;
}

.process__container {
    position: relative;
    color: #b2b4ea;

    &::before {
        content: '';
        background-image: url('../../svg/proces-wave.svg');
        background-repeat: no-repeat;
        position: absolute;
        z-index: 1;
        top: 10%;
        left: 0;
        height: 80%;
        width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 30px;
        font-weight: 700;
        font-style: italic;
        color: #b2b4ea;
    }
}

.process__item {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:nth-child(1n) {
        margin-left: 200px;
    }

    &:nth-child(2n) {
        margin-left: 0;
    }

    &:nth-child(3n) {
        margin-left: 230px;
    }

    &:nth-child(4n) {
        margin-left: -10px;
    }

    &:nth-child(5n) {
        margin-left: 200px;
    }
}

.process__item__image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primarycolor;
    padding: 40px;
    height: 200px;
    width: 200px;
    border-radius: 100%;

    img {
        width: 100%;
    }
}

.process_item__content {
    flex: 1;
    padding-left: 20px;
}

.application__anchor {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 80px 0;
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    font-style: italic;
    color: #fff;

    svg {
        position: relative;
        top: 0;
        margin-top: 15px;
        height: 30px;
        width: 30px;
        color: #fff;
        fill: #fff;
        transition: all 0.3s ease;
    }

    &:hover {
        color: #fff;
        text-decoration: none;

        svg {
            top: 5px;
        }
    }
}

.app__outer {
    display: block;
    position: relative;
    width: 100%;
}

.app__inner {
    background-color: #fff;
    position: relative;
    color: $body-font-color;
    padding-left: 20px;
    padding-right: 20px;

    &:hover {
        color: $body-font-color;
    }
}

.app__inner__image {
    position: relative;
    width: auto;
    padding: 0;
}

.app__inner__title {
    display: block;
    position: relative;
    font-size: 20px;
    font-weight: 700;
    color: $primarycolor;
    padding-top: 15px;
    padding-bottom: 15px;
    max-width: 375px;
}

.app__title {
    height: auto;
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: $primarycolor;
    margin-bottom: 50px;
}

.app__summary {
    position: relative;
    display: block;
    max-width: 375px;
    word-break: break-all;
}

.app__button {
    display: inline-block;
    position: relative;
    margin-top: 50px;
    align-self: flex-start;
}

.quote__container {
    position: relative;
    display: block;
    margin: 0 auto;
    background-image: linear-gradient(-80deg, #f4f4f4 0%, #eaeaea 100%);
    max-width: 1153px;
    padding: 60px 130px;
    text-align: center;

    .quote__container__content {
        font-weight: 500;
        font-style: italic;
        font-size: 25px;
        color: $primarycolor;
    }

    .quote__container__button {
        position: absolute;
        bottom: -15px;
        left: 0;
    }

    .quote__marks__before svg,
    .quote__marks__after svg {
        width: 50px;
        height: 40px;
    }

    .quote__marks__before {
        position: absolute;
        top: -20px;
        left: -80px;
        display: block;
    }

    .quote__marks__after {
        position: absolute;
        bottom: -20px;
        right: -80px;
        display: block;

        svg {
            transform: rotate(180deg);
        }
    }

    .btn {
        margin-bottom: -20px;
    }
}

.team__title__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 20px;

    .team__icon {
        display: block;
        position: relative;
        margin-right: 50px;

        svg {
            width: 140px;
            height: 84px;
        }
    }
}

.team__title {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        position: relative;
        display: block;
        font-size: 50px;
        font-weight: 500;
        font-style: italic;
        color: $primarycolor;
        line-height: 1;

        strong {
            font-weight: 700;
            display: block;
        }
    }
}

.home__team__container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-top: 50px;

    p {
        position: relative;
        margin-top: 10px;
        display: block;
        font-weight: 300;
        font-size: 18px;
        color: #484848;
    }
}

.home__team__left,
.home__team__right {
    position: relative;
    display: flex;
    flex: 0 0 50%;
    padding: 0;
    margin: 0;
    flex-direction: column;
}

.home__team__left {
    padding-right: 150px;
}

.team__button {
    position: relative;
    display: inline-block;
    margin-top: 30px;
}

.home__team__right {
    position: relative;
    display: block;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
    border: 10px solid #fff;

    img {
        width: 100%;
        height: 100%;
    }
}

.container-triangle {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: auto;

    svg {
        width: 100%;
        height: auto;
    }
}

.sidebar__image {
    position: relative;
    display: block;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 50px;
    background-color: #f6f6f6;

    img {
        width: 100%;
    }
}

.sidebar__news {
    position: relative;
    display: block;
}

.sidebar__news__header {
    display: block;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    font-style: italic;
    color: $primarycolor;
}

.sidebar__news__item {
    &:not(:last-child) {
        margin-bottom: 25px;
    }
}

.sidebar__news__item--inner {
    display: block;
    border-radius: 15px;
    background-color: rgba(#ebebeb, 0.62);
    overflow: hidden;
    color: $body-font-color;
}

.sidebar__news__top {
    display: flex;
    justify-content: space-between;

    img {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 0 15px;
        max-width: 110px;
    }
}

.sidebar__news__date {
    margin-left: 40px;
    padding: 25px 0;
    border-bottom: 1px solid rgba(#707070, 0.15);

    svg {
        width: 16px;
        height: 18px;
        margin-right: 8px;
        color: $secondarycolor;
        fill: $secondarycolor;
    }
}

.sidebar_news__content {
    display: block;
    padding: 25px 40px;
}

.sidebar__news__title {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: $primarycolor;
}

.sidebar__specs {
    display: block;
    border-radius: 30px;
    background-color: rgba(#ebebeb, 0.62);
    padding: 30px 40px;
}

.sidebar__spec__header {
    display: block;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 700;
    color: $primarycolor;
}

.sidebar__specs__item {
    display: inline-table;
    width: 100%;
    font-size: 16px;

    &:not(:last-child) {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(#707070, 0.15);
    }
}

.sidebar__specs__title {
    display: block;
    float: left;
    width: 40%;
    font-weight: 700;
}

.sidebar__specs__text {
    display: block;
    float: left;
    width: 60%;
}

.howitworks__beeldmerk__content {
    position: absolute;
    z-index: 2;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    svg {
        margin-top: 40px;
        width: 33px;
        height: 47px;
        color: #fff;
        fill: #fff;
        opacity: 0.3;
    }
}

.howitworks__container {
    padding: 150px 0 120px;
    color: #fff;
    text-align: center;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 30px;
        font-weight: 700;
        font-style: italic;
        color: #fff;
    }
}

.howitworks__spacer {
    position: relative;
    display: block;
    margin-top: 45px;

    svg {
        width: 33px;
        height: 47px;
        color: #fff;
        fill: #fff;
        opacity: 0.3;
    }
}

.howitworks__item__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 45px;
}

.howitworks__item__image {
    display: block;
    background-color: #fff;
    border-radius: 100%;
    width: 185px;
    overflow: hidden;
    border: 10px solid #fff;
    margin: 0 20px 20px;
}

.howitworks__content__block {
    display: block;
    margin-top: 45px;
}

.howitworks__content__block__title {
    display: block;
    font-size: 24px;
    font-weight: 600;
    font-style: italic;
}

.howitworks__item__group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: -40px 60px 0 50px;
}

.howitworks__item__color {
    position: relative;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    border: 5px solid #fff;
    overflow: hidden;
    margin-left: -10px;

    &:nth-child(even) {
        margin-top: 120px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(242deg, rgba(255, 255, 255, 0) 0%, #fff 39%, rgba(255, 255, 255, 0) 55%, rgba(255, 255, 255, 0.31) 100%);
        opacity: 0.57;
    }
}

.howitworks__footer {
    display: block;
    margin-top: 100px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
    border-radius: 30px;
    background-color: #fff;
    padding: 80px 90px;
    color: $primarycolor;
    text-align: left;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $primarycolor;
    }
}

.howitworks__proces {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
}

.howitworks__proces__content {
    display: block;
    margin-top: 40px;
    width: 100%;
}

.howitworks__proces__icon {
    width: 150px;
    margin: 0 20px;

    img {
        width: 100%;
    }
}

.howitworks__proces__logo {
    width: 200px;
    margin: 0 20px;

    img {
        width: 100%;
    }
}

.partners__group {
    position: relative;
    display: block;
    padding: 20px 0;
}

.partners__slider__group > .slick-list > .slick-track {
    margin-left: 0;
}

.partners__group__title {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f5f5f5;
    font-size: 20px;
    font-weight: 700;
    color: #2c2f84;
}

.partners__group__container {
    display: block;
    padding: 30px;
    border: 1px solid #ddd;
}

.partners__item {
    display: flex;
    flex-direction: row;
    width: 100%;

    &:not(:last-child) {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #ddd;
    }
}

.partners__item__image {
    width: 225px;

    img {
        width: 100%;
    }
}

.partners__item__content {
    flex: 1;
    padding-left: 40px;
    color: $body-font-color;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        display: block;
        font-size: 24px;
        font-weight: 700;
        color: $primarycolor;
        margin-bottom: 20px;
    }
}

.partner__item__link {
    display: block;
    margin-top: 20px;
    font-weight: 600;

    svg {
        position: relative;
        top: -1px;
        margin-left: 5px;
        height: 19px;
        width: 11px;
        fill: $primarycolor;
        color: $primarycolor;
        transition: all 0.3s ease;
    }

    &:hover {
        svg {
            margin-left: 9px;
        }
    }
}

.team__item {
    display: flex;
    flex-direction: column;
}

.faq__intro {
    margin-bottom: 30px;
}

.accordion {
    &:not(:last-child) {
        margin-bottom: 60px;
    }

    &:last-child {
        .accordion {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }
    }

    .card-header {
        position: relative;

        a {
            display: block;
            padding-right: 15px;
        }

        svg {
            position: absolute;
            top: 15px;
            right: 10px;
            height: 22px;
            width: 22px;
        }
    }
}
