/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

$primarycolor: #2c2f84;
$secondarycolor: #1fa6e3;

$font-primary: 'Montserrat', sans-serif;
$font-secondary: '';
$body-font-size: 18px;
$body-font-weight: 300;
$body-line-height: 1.6;
$body-font-color: #484848;

$header-font-family: 'Montserrat', sans-serif;
$header-color: $primarycolor;
$header-font-weight: bold;

$theme-colors: (
    primary: $primarycolor
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

//Bootstrap
$grid-gutter-width: 40px !default;
$enable-responsive-font-sizes: true;
$h1-font-size: 38px;
$h2-font-size: 34px;
$h3-font-size: 29px;
$h4-font-size: 24px;
$h5-font-size: 20px;
