// Page
//
// containers

.row--page {
    margin-bottom: 35px;

    .col {
        > p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.row--gallery {
    .col {
        &:nth-child(n+5) {
            margin-top: 30px;
        }
    }
}

.page__slider {
    position: relative;

    .slick-dots {
        bottom: -43px;
    }

    .page__slider__item {
        a {
            &:focus {
                outline: none;
            }
        }

        &:focus {
            outline: none;
        }
    }

    &.slick-dotted {
        &.slick-slider {
            margin-bottom: 40px;
        }
    }

    .slick-arrow {
        position: absolute;
        font-size: 36px;
        text-align: center;
        top: 50%;
        margin-top: -25px;
        cursor: pointer;
        width: 50px;
        height: 50px;

        &.fa-angle-left {
            left: -40px;
        }

        &.fa-angle-right {
            right: -40px;
        }
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

.partner__slider__inner {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
}

.founding::before {
    content: "Founding Partners";
    position: absolute;
    left: -2px;
    bottom: calc(100% + 1px);
    padding: 10px 20px;
    background-color: #f5f5f5;
    width: auto;
    line-height: 2.8;
    z-index: 99;
    font-size: 20px;
    font-weight: 700;
    color: #2c2f84;
}

.funding::before {
    content: "Partners";
    position: absolute;
    left: -2px;
    bottom: calc(100% + 1px);
    padding: 10px 20px;
    background-color: #f5f5f5;
    width: auto;
    line-height: 2.8;
    z-index: 99;
    font-size: 20px;
    font-weight: 700;
    color: #2c2f84;
}

.partner__slider__slick__right {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
}

.partner__slider__slick__left {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
}

.partner__slider__block__left {
    position: relative;
    display: block;

    img {
        width: 100%;
    }
}

.partner__slider__block__left,
.partner__slider__block__right {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    min-height: 160px;
}

.team__image {
    overflow: hidden;
}

@media screen and (max-width: 1275px) {
    .row--slider {
        padding: 0 40px;
    }
}

@media screen and (max-width: 991px) {
    .row--gallery {
        .col {
            &:nth-child(n+4) {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .row--columns {
        > .col {
            margin-bottom: 20px;
        }
    }

    .row--gallery {
        .col {
            &:nth-child(n+3) {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 380px) {
    .row--gallery {
        > .col {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 30px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}
