@media screen and (max-width: 1199px) {
    .spacing {
        padding-top: 50px;
        padding-bottom: 50px;
        margin: 0;
    }
    
    .nav-primary {
        a {
            font-size: 15px;
        }
    }
    
    .subpage__beeldmerk {
        padding-top: 200px;
    }
    
    .partner__slider__slick__left {
        margin: 0;
    }
    
    .quote__outer {
        padding-left: 50px;
        padding-right: 50px;
    }
    
    .front__intro {
        padding: 50px 0 350px;
    }
    
    .howitworks__intro {
        padding-bottom: 120px;
    }
    
    .video__intro {
        padding-top: 20px;
        padding-bottom: 150px;
    }
    
    .front__beeldmerk__content {
        bottom: 15%;
        right: 6%;
        
        svg {
            margin-top: 30px;
            width: 28px;
            height: 41px;
        }
    }
    
    .team__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 42px;
        }
    }
    
    .home__team__left {
        padding-right: 50px;
    }
    
    .app__parent {
        margin-bottom: 50px;
    }
    
    .team__title__container {
        .team__icon {
            margin-right: 25px;
            
            svg {
                width: 90px;
                height: 54px;
            }
        }
    }
    
    .front__beeldmerk__title {
        font-size: 36px;
        line-height: 42px;
    }
    
    .siderbar {
        margin-top: 50px;
    }
    
    .howitworks__item__image {
        width: 145px;
    }
    
    .contact__page {
        padding-bottom: 10px;
    }
    
    .contact__title {
        margin: 55px 0 50px 0;
        
        h1 {
            font-size: 30px;
            margin-bottom: 0;
        }
    }
    
    .contact__right__titel {
        margin: 30px 0 10px 0;
        
        h2 {
            margin-bottom: 10px;
        }
    }
    
    .contact__right__details {
        padding: 40px;
    }
    
    .contact__right__container {
        padding-left: 0;
        
        .footer {
            padding: 150px 0;
        }
    }

    .sidebar__news__top img {
        height: 75px;
    }
}
