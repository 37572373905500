body {
    font-family: $font-primary;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    color: $body-font-color;
    
    &.admin-bar {
        .header,
        .header.header--fix {
            top: 32px;
        }
    }
}

.main {
    padding-top: 200px;
}

.block {
    position: relative;
    padding: 0;
}

.fournullfour {
    position: relative;
    display: block;
    padding: 100px 0;
    margin-bottom: 100px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font-family;
    color: $header-color;
    font-weight: $header-font-weight;
}

h1,
h2 {
    margin-bottom: 30px;
}

h3,
h4 {
    margin-bottom: 20px;
}

h5,
h6 {
    margin-bottom: 15px;
}

a {
    &:hover {
        text-decoration: none;
    }
}

.acf-map img {
    max-width: inherit !important;
}

.slick-slide {
    outline: none;
}

.img-fluid {
    width: 100%;
}
