.contact__form {
    margin-top: 30px;
}

.footer__contact__form {
    display: none;
    width: 100%;
    max-width: 600px;
}

.contact__form__title {
    display: block;
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 700;
}

.contact__form__text {
    display: block;
    margin-bottom: 25px;
}

.page-contact-data {
    .page__inner {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

// form
.gfield {
    margin-bottom: 15px;

    input,
    select,
    textarea {
        background-color: transparent;
        box-shadow: 0 0 0 transparent;
        border: 1px solid #d4d4d4;
        border-radius: 5px;
        height: 39px;
        padding: 10px 15px;
        width: 100%;
        font-size: 15px;
    }

    textarea {
        height: auto;
    }
}

.gfield_checkbox {
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 24px;

        label {
            margin: 0;
        }

        input {
            width: auto;
            margin-right: 12px;
            height: auto;
        }
    }
}

.clear-multi {
    display: flex;
    margin: 0 -10px;

    > * {
        padding: 0 10px;
    }
}

.ginput_container_list {
    table {
        width: 100%;
    }
    
    tbody {
        display: flex;
        flex-direction: column;
    
        > tr {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
        
            .gfield_list_cell {
                width: 100%;
            }
            
            .gfield_list_icons {
                display: flex;
                align-items: center;
                justify-content: center;

                > a {
                    margin: 0 3px;
                }
            }
        }
    }
}

.gfield_time_ampm {
    min-width: 85px;
    font-size: 14px;
}

.ginput_container_name {
    display: flex;
    margin: 0 -15px;

    > span {
        padding: 0 15px;
        flex: 0 0 50%;
        max-width: 50%;
    }

    label {
        display: block;
        order: 0;
    }
}

.ginput_container_post_image {
    input {
        padding: 4px;
    }
}

.ginput_container_fileupload {
    > input {
        padding: 4px;
    }
}

.ginput_container_address {
    > span {
        margin-bottom: 15px;
        display: block;

        > label {
            display: block;
            margin-top: 4px;
        }
    }
}

.ginput_container_consent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -15px;

    a {
        text-decoration: underline;
        color: #138e9d;
    }

    > input {
        width: auto;
        height: 23px;
        margin-right: 15px;
    }

    > label {
        margin-bottom: 0;
    }
}

.gfield_radio {
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 24px;

        label {
            margin: 0;
        }

        input {
            width: auto;
            margin-right: 12px;
            height: auto;
        }
    }
}

.ginput_container_multiselect {
    select {
        height: auto;
    }
}

.gfield_required {
    display: block;
    position: relative;
    margin-left: 5px;
    color: $primarycolor;
    font-size: 18px;
}

.gfield_label {
    color: $primarycolor;
    font-weight: 300;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    flex-direction: row;
}

.gform_fields {
    padding: 0;
    margin: 0 -15px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    > li {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;

        &.full {
            flex: auto;
            width: 100%;
            max-width: 100%;
        }
    }
}

.validation_error {
    color: red;
    margin: 0 0 15px;
    font-size: 13px;
}

.validation_message {
    color: red;
    font-size: 13px;
    margin: 5px 0 0;
}

.gform_button {
    @extend .btn-primary;

    &:active {
        @extend .btn-primary;
    }
}

.gform_ajax_spinner {
    margin-left: 15px;
}

#ui-datepicker-div {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: (0 3px 5px 0 rgba(50, 50, 50, 0.75));
    display: none;
    padding: 20px;
    width: 300px;
 
    .ui-icon {
        color: transparent;
        cursor: pointer;
        font-size: 0;
    }

    .ui-icon::before {
        font-size: 18px;
    }
    
    .ui-datepicker-prev {
        float: left;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        .ui-icon::before {
            content: '';
            background-image: url('../../svg/angle-left-regular.svg');
            float: left;
            background-size: 9px;
            width: 10px;
            height: 19px;
            background-repeat: no-repeat;
            position: relative;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-next {
        float: right;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .ui-icon::before {
            content: '';
            background-image: url('../../svg/angle-right-regular.svg');
            float: right;
            background-size: 9px;
            width: 10px;
            height: 19px;
            background-repeat: no-repeat;
            position: relative;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-header {
        display: block;
        float: left;
        width: 100%;
        margin-top: 12px;
    }
    
    .ui-datepicker-title {
        select {
            float: left;
            width: 70%;
        }

        .ui-datepicker-month {
            margin: 0 5% 6px;
            border-radius: 4px;
            padding: 3px;
            border: 1px solid #cfcfcf;
        }

        .ui-datepicker-year {
            margin: 0 15% 6px;
            border-radius: 4px;
            padding: 3px;
            border: 1px solid #cfcfcf;
        }
    }

    .ui-datepicker-today {
        background-color: #e6eef1;
        border-radius: 4px;

        a {
            color: #2a4982;
        }
    }
    
    table {
        width: 100%;

        td,
        th {
            text-align: center;
        }

        td {
            a {
                display: block;
                padding: 5px;
                border-radius: 4px;

                &:hover {
                    background-color: $primarycolor;
                    color: #fff;
                    text-decoration: none;
                }

                &.ui-state-active {
                    background-color: $primarycolor;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .gform_fields > li {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
