@media screen and (max-width: 991px) {
    body {
        &.menu__open {
            .nav-primary__overlay {
                transition: all 300ms ease-in-out;
                opacity: 1;
                pointer-events: all;
            }

            .nav-primary {
                left: 0;
                transition: all 300ms ease-in-out;
            }
        }
    }

    .subpage__beeldmerk {
        padding-top: 300px;
    }

    .nav-primary__overlay {
        display: block;
        opacity: 0;
        pointer-events: none;
        transition: all 300ms ease-in-out;
    }

    .menu__icon {
        display: block;
        position: absolute;
        top: 25px;
        right: 25px;
    }

    .nav-primary {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        position: fixed;
        top: 0;
        left: -70%;
        width: 70%;
        height: 100%;
        background-color: #fff;
        transition: all 300ms ease-in-out;
        flex-direction: column;
        justify-content: flex-start;

        a {
            padding: 12px 24px !important;
        }
    }

    .nav-primary ul .fa-angle-down,
    .nav-primary li .fa-angle-down {
        float: right;
        margin-top: 6px;
    }

    .nav-primary .nav .sub-menu.sub-menu1 > li .fa-angle-right {
        float: right;
        margin-top: 6px;
        transform: rotate(90deg);
    }

    .nav-primary .nav {
        width: 100%;
    }

    .nav-primary .nav > li {
        float: left;
        width: 100%;
        border-bottom: 1px solid #eee;
    }

    .nav-primary .nav .sub-menu {
        position: relative;
    }

    .nav-primary .nav .sub-menu.sub-menu1 {
        top: 0;
        left: 0;
        border-top: 1px solid #eee;
    }

    .nav-primary .nav .sub-menu.sub-menu1 > li a {
        padding-left: 25px;
    }

    .nav-primary .nav .sub-menu.sub-menu2 {
        top: 0;
        left: 0;
        border-top: 1px solid #eee;
    }

    .nav-primary .nav .sub-menu.sub-menu2 > li a {
        padding-left: 35px;
    }

    .nav-primary .nav .sub-menu.sub-menu3 {
        top: 0;
        left: 0;
        border-top: 1px solid #eee;
    }

    .nav-primary .nav .sub-menu.sub-menu3 > li a {
        padding-left: 45px;
    }

    .nav__home {
        border-bottom: 1px solid #eee;
        color: #fff;
        display: flex;
        justify-content: flex-start;
        padding: 12px 24px;
        width: 100%;
        margin: 0;

        svg {
            top: 0;
            height: 22px;
            width: 22px;
        }
    }

    .spacing {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .footer {
        padding: 100px 0 0;
    }

    .footer__nav {
        margin-top: 80px;
    }

    .footer__top__title {
        font-size: 38px;
        line-height: 38px;
    }

    .footer__top__sub {
        font-size: 20px;
        line-height: 20px;
    }

    .partner__container {
        margin: 150px 0 50px 0;
    }

    .app__title {
        margin-bottom: 30px;
    }

    .app__parent {
        margin-bottom: 50px;
    }

    .quote__container .quote__container__content {
        font-size: 22px;
    }

    .quote__outer {
        padding-left: 50px;
        padding-right: 50px;
    }

    .home__team__container {
        flex-direction: column;
        margin: 0;
        padding: 50px 0 0;
    }

    .home__team__right {
        display: none;
    }

    .home__team__left {
        padding-right: 0;
    }

    .team__title__container {
        margin-bottom: 0;

        .team__icon {
            margin-right: 15px;

            svg {
                width: 90px;
                height: 54px;
            }
        }
    }

    .team__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 32px;
        }
    }

    .main__container {
        padding: 50px 60px;
    }

    .header {
        padding-top: 20px;
    }

    .main {
        padding-top: 100px;
    }

    .howitworks__container {
        padding: 150px 0 60px;
    }

    .howitworks__beeldmerk__content {
        bottom: -140px;
    }

    .howitworks__footer {
        padding: 40px 50px;
    }

    .video__intro {
        padding-top: 20px;
        padding-bottom: 150px;
    }

    .contact__right__details {
        padding: 30px;
    }

    .contact__left__required {
        margin-top: -95px;
        justify-content: flex-end;

        span {
            font-size: 13px;
        }
    }

    .contact__left__text {
        margin-bottom: 20px;
    }

    .gform_footer {
        margin-top: 20px;
    }

    .main__container__contact {
        padding: 50px 60px 80px 60px;
    }

    .partners__item {
        flex-direction: column;
    }

    .partners__item__content {
        padding: 20px 0 0 0;
    }
}
