@media screen and (max-width: 575px) {
    .btn-primary,
    .btn-secondary {
        font-size: 14px;
        padding: 9px 22px;

        &::after {
            display: none;
        }

        &::before {
            display: none;
        }
    }

    .spacing {
        padding-top: 0;
        padding-bottom: 50px;
    }

    .brand {
        max-width: 175px;
    }

    .footer {
        padding: 75px 0 0;
    }

    .footer__nav {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }

    .footer__top__sub {
        font-size: 16px;
        line-height: 1.8;
    }

    .footer__top__title {
        font-size: 30px;
    }

    .footer__bottom__btn {
        height: 16px;
        font-size: 16px;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .footer__bottom__btn__icon__flipped {
        svg {
            height: 20px;
        }
    }

    .quote__container {
        padding: 20px 30px 40px 30px;

        .quote__container__content {
            font-size: 16px;
        }

        .quote__marks__before {
            left: -10px;
        }

        .quote__marks__after {
            right: -10px;
        }

        .quote__marks__before svg,
        .quote__marks__after svg {
            width: 36px;
            height: 18px;
        }

        .quote__container__button {
            bottom: -2px;
        }
    }

    .footer__bottom__btn__icon {
        svg {
            height: 20px;
        }
    }

    .footer__bottom__btn__spacing {
        margin-right: 15px;
    }

    .app__title {
        margin-bottom: 15px;
    }

    .front__intro {
        padding: 50px 0 200px;
    }

    .howitworks__intro {
        padding-bottom: 170px;
    }

    .video__intro {
        padding-bottom: 225px;
    }

    .quote__outer {
        padding-bottom: 100px;
    }

    .app__parent {
        padding-bottom: 125px;
    }

    .sidebar__spec__header {
        font-size: 19px;
    }

    .sidebar__specs__title,
    .sidebar__specs__text {
        display: block;
        width: 100%;
    }

    .sidebar__news__date {
        margin-left: 25px;
    }

    .sidebar_news__content {
        padding: 20px 25px;
    }

    .sidebar__news__title {
        font-size: 16px;
        line-height: 22px;
    }

    .main__container__contact {
        padding: 30px 20px !important;
    }

    .contact__right__container {
        margin-top: 80px;
    }

    .contact__right {
        margin-top: 0;
    }

    .contact__left__required {
        margin-top: -75px;
    }

    .contact__title h1 {
        font-size: 20px;
    }

    .contact__title {
        margin: 30px 0;
    }
}
